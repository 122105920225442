<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6">
                    <h3 class="d-flex align-items-center mb-0">
                        <i class="ri-pencil-ruler-2-line"></i> {{ $t('label.items') }}
                    </h3>
                </div>
                <div v-if="no_items == 0" class="col-6 text-right">
                    <button type="button" v-if="['Sent', 'Partially Received'].indexOf(purchaseOrderStatus) !== -1" class="btn btn-sm btn-default m-0" @click="showReceiveItemModal()">
                        {{ $t('label.receive items') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="pb-3">
            <table class="table table-flush table-sm">
                <thead class="thead-light">
                    <tr>
                        <th class="px-2">
                            <div class="row p-0 m-0">
                                <div class="col-6">{{ $t('label.description') }}</div>
                                <div class="col-1">{{ $t('label.sku') }}</div>
                                <div class="col-1 text-right">{{ $t('label.quantity') }}</div>
                                <div class="col-1 text-right">{{ $t('label.unit cost') }}</div>
                                <div class="col-1 text-right">{{ $t('label.total') }}</div>
                                <div class="col-1 text-right">{{ $t('label.received') }}</div>
                                <div class="col"></div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody class="border-bottom">
                    <template v-for="(data, index) in paginatedPurchaseOrderItems">
                        <!-- <template> -->
                        <tr :id="data.id" :key="index" @click="expandOrCollapseList(data)">
                            <!-- <tr v-for="(data, index) in paginatedPurchaseOrderItems" :key="index" @click="expandOrCollapseList(data)"> -->
                            <td class="px-2 list-group-item-action collapsed-entry">
                                <div class="row p-0 m-0 align-items-center">
                                    <div class="col-6 d-flex align-items-center"><i class="ri-arrow-down-s-line heading-title"></i> {{ data.description }}</div>
                                    <div class="col-1">{{ data.sku }}</div>
                                    <div class="col-1 text-right">{{ data.quantity }}</div>
                                    <div class="col-1 text-right">${{ Number.parseFloat(data.unit_cost).toFixed(2) }}</div>
                                    <div class="col-1 text-right">${{ Number.parseFloat(data.subtotal).toFixed(2) }}</div>
                                    <div class="col-1 text-right">{{ data.quantity - data.quantity_left }}/{{ data.quantity }} </div>
                                    <div class="col text-center"><i v-if="data.quantity_left == 0" class="ri-check-line check-success"></i></div>

                                </div>
                            </td>
                        </tr>
                        <!-- RECEIVED ITEMS > 0 AND EXPAND TR -->
                        <template v-if="data.purchase_order_received_items.length > 0 && data.show_received_items">
                            <tr :key="'item' + d.id" v-for="d in data.purchase_order_received_items">
                                <td class="px-2 list-group-item-action expanded-entry-body">
                                    <div class="row p-0 m-0 align-items-center">
                                        <div class="col-9 pl-5">{{ d.received_date | formatDate }}</div>
                                        <div class="col-1 text-right">{{ $t('label.received') }}</div>
                                        <div class="col-1 text-right">{{ d.quantity }}</div>
                                        <div class="col"></div>
                                    </div>
                                </td>
                            </tr>
                        </template>

                        <!-- RECEIVED ITEMS == 0 AND EXPAND TR -->
                        <template v-if="data.purchase_order_received_items.length == 0 && data.show_received_items">
                            <tr :key="'none' + index">
                                <td class="px-2 list-group-item-action expanded-entry-body">
                                    <div class="row p-0 m-0 align-items-center">
                                        <div class="col-12 pl-5">Nothing received yet</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="data.show_original_quantity && data.show_original_unit_cost">
                            <div :key="'divider' + index" class="solid"></div>
                            <tr :key="'original' + index" class="text-muted">
                                <td class="px-2 border-0">
                                    <div class="row p-0 m-0">
                                        <div class="col-7 text-right"><b>Original :</b></div>
                                        <div class="col-1 text-right"><b>Quantity</b></div>
                                        <div class="col-1 text-right"><b>Unit Cost</b></div>
                                        <div class="col"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr :key="'original2' + index" class="text-muted">
                                <td class="px-2 border-0">
                                    <div class="row p-0 m-0">
                                        <div class="col-7 text-right"><b></b></div>
                                        <div class="col-1 text-right"><b>{{ data.original_quantity }}</b></div>
                                        <div class="col-1 text-right"><b>${{ Number.parseFloat(data.original_unit_cost).toFixed(2) }}</b></div>
                                        <div class="col"></div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="data.notes && data.show_notes">
                            <tr :key="'notes' + index" class="text-muted">
                                <td class="px-2 border-0">
                                    <div class="row p-0 m-0">
                                        <div class="col-1 text-right"><b></b>Notes:</div>
                                        <div class="col-7"><b>{{ data.notes }}</b></div>
                                        <div class="col"></div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
                <tbody class="border-top">
                    <tr class="ttotal-sub">
                        <td class="px-2 border-0">
                            <div class="row p-0 m-0">
                                <div class="col-8 text-right"><b>{{ $t('label.tax') }}</b></div>
                                <div class="col-1 text-right"><b>{{ Number.parseFloat(tax).toFixed(2) }}%</b></div>
                                <div class="col-1 text-right"><b>${{ Number.parseFloat(taxCalc).toFixed(2) | formatThousands }}</b></div>
                                <div class="col"></div>
                            </div>
                        </td>
                    </tr>
                    <tr class="ttotal">
                        <td class="px-2 border-0">
                            <div class="row p-0 m-0">
                                <div class="col-8 text-right"></div>
                                <div class="col-1 text-right">
                                    <h4>{{ $t('label.total') }}</h4>
                                </div>
                                <div class="col-1 text-right">
                                    <h4>${{ overall_total | formatThousands }}</h4>
                                </div>
                                <div class="col"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table-pagination v-model="paginatedPurchaseOrderItems" :all-rows="purchaseOrderItems" :perPage="perPage">
            </table-pagination>
        </div>
        <!-- Receive Items Modal -->
        <modal class="py-4" name="receiveItemModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ReceiveItemModal :purchase-order-item="purchaseOrderItems" :purchase-order-id="purchaseOrderId" @cancelFnc="hideReceiveItemModal" @reload="loadLkupData"></ReceiveItemModal>
        </modal>
    </div>
</template>

<script>
import TablePagination from '@/components/TablePagination';
import ReceiveItemModal from '../../modal/ReceiveItemModal.vue';

export default {
    props: ['purchaseOrderId', 'purchaseOrderStatus', 'purchaseOrderInfo'],
    emits: [''],
    components: {
        TablePagination,
        ReceiveItemModal
    },
    data() {
        return {
            paginatedPurchaseOrderItems: [],
            allPurchaseOrderItems: [],
            perPage: 10,
            entryDropdownIdx: 0,
            sub_total: 0,
            overall_total: 0,
            tax: 0,
            taxCalc: 0,
            no_items: 1,
            purchaseOrderItems: [],
            showEdit: false,
        }
    },
    computed: {
        // ...mapGetters({
        //     purchaseOrderItems:'purchaseOrder/purchaseOrderItems',
        // }),
    },
    mounted() {
        this.loadLkupData();
    },
    methods: {
        loadLkupData() {
            let id = this.purchaseOrderId;
            axios.get('/get-purchase-order-items/' + id)
                .then(response => {
                    this.purchaseOrderItems = response.data.result;
                    this.no_items = response.data.no_items;
                    if (this.no_items) {
                        this.overall_total = Number.parseFloat(response.data.overall_total).toFixed(2);

                    } else {
                        this.sub_total = 0;
                        this.purchaseOrderItems.forEach((data, index) => {
                            data.subtotal = 0;
                            data.subtotal += parseFloat(data.quantity) * parseFloat(data.unit_cost);

                            this.tax = data.taxes;
                            data.total = data.subtotal + (data.subtotal * (this.tax / 100));

                            this.sub_total += data.subtotal;
                            data.subtotal = parseFloat(data.subtotal).toFixed(2);
                        })

                        this.taxCalc = this.sub_total * (this.tax / 100);
                        this.overall_total = Number.parseFloat(this.sub_total + this.taxCalc).toFixed(2);
                    }
                })
                .catch(err => console.log('getPurchaseOrderItems', err));
        },
        expandOrCollapseList(data) {
            data.show_received_items = !data.show_received_items;
            data.show_original_quantity = !data.show_original_quantity;
            data.show_original_unit_cost = !data.show_original_unit_cost;
            data.show_notes = !data.show_notes;
        },
        showReceiveItemModal() {
            this.$modal.show('receiveItemModal');
        },
        hideReceiveItemModal() {
            this.$modal.hide('receiveItemModal');
        },
    },
}
</script>
<style scoped>
.solid {
    border-top: 1px solid #e9ecef
}
</style>
