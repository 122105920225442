import { render, staticRenderFns } from "./PurchaseOrderView.vue?vue&type=template&id=040c9f48&"
import script from "./PurchaseOrderView.vue?vue&type=script&lang=js&"
export * from "./PurchaseOrderView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports