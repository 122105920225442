<template>
    <div>
        <!-- Big screen sidebar -->
        <nav class="sp-sidenav" id="sidenav-main" :class="{ 'hidden-lg-down': (!showNav && navTogglable) }">
            <perfect-scrollbar class="h-100">

                <div class="text-right mb--2 pointer text-gray-800" @click="toggleNavBar()">
                    <i class="ri-close-circle-line hidden-xl-up"></i>
                </div>
                <div class="d-flex align-items-center">
                    <!-- Logo -->
                    <router-link :to="{ name: 'jobs' }">
                        <a class="text-center">
                            <img :src="require('@/assets/images/SubPro_Logo_beta.png')" class="img-fluid" style="max-width: 70%;margin-top: 20px;" alt="...">
                        </a>
                    </router-link>

                    <!-- Language Selector -->
                    <!-- <div class="dropdown">
                        <a class="btn btn-secondary" data-toggle="dropdown" id="navbarDropdownMenuLink">
                            <img v-if="language" class="lang-img" :src="require(`@/assets/images/flags/${language}.png`)">
                            <i class="fa fa-caret-down"></i>
                        </a>
                        <ul class="dropdown-menu lang-toggle dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                                <a class="dropdown-item pointer" @click="setLang('en')">
                                    <img class="lang-img" :src="require('@/assets/images/flags/en.png')" />
                                    {{ $t('label.english') }}
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item pointer" @click="setLang('es')">
                                    <img class="lang-img" :src="require('@/assets/images/flags/es.png')" />
                                    {{ $t('label.spanish') }}
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item pointer" @click="setLang('pt')">
                                    <img class="lang-img" :src="require('@/assets/images/flags/pt.png')" />
                                    {{ $t('label.portuguese') }}
                                </a>
                            </li>
                        </ul>
                    </div> -->
                </div>

                <!-- Menu Items -->
                <div class="mt-1" style="padding-bottom:180px">
                    <!-- Subpro Super Admin -->
                    <ul class="navbar-nav" v-if="user.role_id == 1">
                        <li class="nav-item">
                            <router-link :to="{ name: 'dashboard' }">
                                <span class="nav-link d-flex align-items-center" @click="toggleNavBar()" :class="{ 'active': isActive('dashboard') }">
                                    <i class="ri-dashboard-line"></i>
                                    <span class="nav-link-text">{{ $t('label.dashboard') }}</span>
                                </span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'tenant-list' }">
                                <span class="nav-link d-flex align-items-center" style="width: -webkit-fill-available;" @click="toggleNavBar()" :class="{ 'active': isActive('tenant') }">
                                    <i class="ri-building-line"></i>
                                    <span class="nav-link-text">Manage Tenants</span>
                                </span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link d-flex align-items-center collapsed" href="#navbar-payemnts" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="navbar-payemnts">
                                <i class="ri-checkbox-blank-circle-fill"></i>
                                <span class="nav-link-text">Products & Plans</span>
                            </a>

                            <div class="collapse" id="navbar-payemnts" style="" :class="{ 'show': isActive('plans') || isActive('products') || isActive('add-ons') }">
                                <ul class="nav nav-sm flex-column">
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'list-product' }" class="nav-link" :class="{ 'active': isActive('products') }">
                                            <span class="nav-link d-flex align-items-center pl-2" @click="toggleNavBar()">
                                                <i class="ri-shopping-bag-line"></i>
                                                <span class="nav-link-text">Products</span>
                                            </span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'list-plan' }" class="nav-link" :class="{ 'active': isActive('plans') }">
                                            <span class="nav-link d-flex align-items-center pl-2" @click="toggleNavBar()">
                                                <i class="ri-send-plane-line"></i>
                                                <span class="nav-link-text">Plans</span>
                                            </span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'list-add-ons' }" class="nav-link" :class="{ 'active': isActive('add-ons') }">
                                            <span class="nav-link d-flex align-items-center pl-2" @click="toggleNavBar()">
                                                <i class="ri-links-line"></i>
                                                <span class="nav-link-text">Add-ons</span>
                                            </span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>

                    <!-- Office User -->
                    <ul class="navbar-nav" v-if="user.role_id == 2">
                        <!-- Getting Started -->
                        <!-- <li class="nav-item">
                                <a class="nav-link d-flex align-items-center" href="#" data-toggle="modal" data-target="#gettingStartedModal" @click="toggleNavBar()">
                                    <i class="">&#128075;</i>
                                    <span class="nav-link-text">Getting Started</span>
                                </a>
                        </li> -->
                        <!-- Home -->
                        <li class="row my-2 sp-line-separator"></li>
                        <li class="nav-item">
                            <a class="nav-link d-flex align-items-center collapsed" href="#navbar-home" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="navbar-home">
                                <i class="ri-home-smile-line"></i>
                                <span class="nav-link-text">Home</span>
                            </a>
                            <div class="collapse" id="navbar-home" style="" :class="{ 'show': isActive('timesheet') || isActive('myTimeOffRequest') }">

                        <li class="nav-item pl-2">
                            <a class="pointer" @click="viewClockInModal = true; viewClockOutModal = false;" v-if="hasLoaded == false && company.settings">
                                <i class="ri-time-line"></i>
                                Clock In
                            </a>
                            <a class="pointer" v-if="hasLoaded" @click="viewClockOutModal = true; viewClockInModal = false;">
                                <i class="ri-time-line"></i>
                                Clock Out
                            </a>
                        </li>
                        <li class="nav-item" :class="{ 'active': isActive('myTimeOffRequest') }">
                            <router-link :to="{ name: 'myTimeOffRequest' }">
                                <span class="d-flex align-items-center pl-2" @click="toggleNavBar()">
                                    <i class="ri-calendar-event-line"></i>
                                    <span class="nav-link-text">My Time Off</span>
                                </span>
                            </router-link>
                        </li>
                </div>
                </li>
                <li class="row my-2 sp-line-separator"></li>
                <!-- Everyone has access except Super-Admin -->
                <!-- <span v-if="user.role_id != 1"> -->
                <!-- Dashboard -->
                <li class="nav-item" :class="{ 'active': $route.name == 'dashboard' }">
                    <router-link :to="{ name: 'dashboard' }">
                        <i class="ri-dashboard-line"></i>
                        <span class="nav-link-text">{{ $t('label.dashboard') }}</span>
                    </router-link>
                </li>

                <!-- Calendar -->
                <li class="nav-item" :class="{ 'active': $route.name == 'calendar' }">
                    <router-link :to="{ name: 'calendar' }">
                        <i class="ri-calendar-line"></i>
                        <span class="nav-link-text">{{ $t('label.calendar') }}</span>
                    </router-link>
                </li>

                <!-- Time management -->
                <li class="nav-item">
                    <a class="nav-link d-flex align-items-center collapsed" href="#navbar-time-managemnt" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="navbar-time-managemnt">
                        <i class="ri-timer-line"></i>
                        <span class="nav-link-text">Time Management</span>
                    </a>
                    <div class="collapse" id="navbar-time-managemnt" :class="{ 'show': isActive('timesheet') || isActive('payrollReport') }">

                <li class="nav-item pl-2" :class="{ 'active': isActive('timesheet') }">
                    <router-link :to="{ name: 'timesheet' }">
                        <i class="ri-time-line"></i>
                        <span class="nav-link-text">{{ $t('label.timesheet') }}</span>
                    </router-link>
                </li>
                <li class="nav-item pl-2" :class="{ 'active': isActive('payrollReport') }">
                    <router-link :to="{ name: 'payrollReport' }">
                        <i class="ri-money-dollar-circle-line"></i>
                        <span class="nav-link-text">Payroll Report</span>
                    </router-link>
                </li>
    </div>
    </li>

    <!-- Financials -->
    <li class="nav-item">
        <a class="nav-link d-flex align-items-center collapsed" href="#navbar-Financials" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="navbar-Financials">
            <i class="ri-bank-line"></i>
            <span class="nav-link-text">Financials</span>
        </a>
        <div class="collapse" id="navbar-Financials" style="" :class="{ 'show': isActive('purchase-orders') || isActive('subscriptions') || isActive('vpos') || isActive('invoiceReport') || isActive('invoiceItems') }">
            <!-- purchase orders  -->
    <li class="nav-item pl-2" :class="{ 'active': isActive('purchase-orders') }">
        <router-link :to="{ name: 'purchaseOrders' }">
            <i class="ri-article-line"></i>
            <span class="nav-link-text">{{ $t('label.purchase orders') }}</span>
        </router-link>
    </li>
    <!-- Variable Purchase Orders -->
    <li class="nav-item pl-2" :class="{ 'active': isActive('vpos') }">
        <router-link :to="{ name: 'variablePurchaseOrders' }">
            <i class="ri-newspaper-line"></i>
            <span class="nav-link-text">{{ $t('label.vpos') }}</span>
        </router-link>
    </li>
    <!-- Invoices -->
    <li class="nav-item pl-2" :class="{ 'active': isActive('invoiceReport') }">
        <router-link :to="{ name: 'invoiceReport' }">
            <i class="ri-bill-line"></i>
            <span class="nav-link-text">Invoices</span>
        </router-link>
    </li>
    <!-- Invoice Items -->
    <li class="nav-item pl-4" :class="{ 'active': isActive('invoiceItems') }">
        <router-link :to="{ name: 'invoiceItems' }">
            <i class="ri-draft-line"></i>
            <span class="nav-link-text">{{ $t('label.invoice items') }}</span>
        </router-link>
    </li>
    <!-- Subscriptions -->
    <li class="nav-item pl-2" :class="{ 'active': isActive('subscriptions') }">
        <router-link :to="{ name: 'subscriptions' }">
            <i class="ri-draft-line"></i>
            <span class="nav-link-text">Subscriptions</span>
        </router-link>
    </li>
    </div>
    </li>

    <li class="row my-2 sp-line-separator"></li>

    <!-- Jobs -->
    <li class="nav-item" :class="{ 'active': $route.name == 'jobs' }">
        <router-link :to="{ name: 'jobs' }">
            <i class="ri-stack-line"></i>
            <span class="nav-link-text">{{ $t('label.jobs') }}</span>
        </router-link>
    </li>
    <!-- Reports -->
    <li class="nav-item" :class="{ 'active': $route.path.indexOf('reports') >= 0 }">
        <router-link :to="{ name: 'reports' }">
            <i class="ri-bar-chart-box-line"></i>
            <span class="nav-link-text">{{ $t('label.reports') }}</span>
        </router-link>
    </li>
    <!-- Conatacts  -->
    <li class="nav-item">
        <a class="nav-link d-flex align-items-center collapsed" href="#navbar-Conatacts" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="navbar-Conatacts">
            <i class="ri-contacts-line"></i>
            <span class="nav-link-text">Contacts</span>
        </a>
        <div class="collapse" id="navbar-Conatacts" style="" :class="{ 'show': isActive('contacts') || isActive('vendors') || isActive('contactLeadLabels') }">
    <li class="nav-item pl-2" :class="{ 'active': isActive('contacts') }">
        <router-link :to="{ name: 'contacts' }">
            <i class="ri-contacts-book-2-line"></i>
            <span class="nav-link-text">Customers/Leads</span>
        </router-link>
    </li>
    <!-- Vendors -->
    <li class="nav-item pl-2" :class="{ 'active': isActive('vendors') }">
        <router-link :to="{ name: 'vendors' }">
            <i class="ri-contacts-book-2-line"></i>
            <span class="nav-draft-line">{{ $t('label.vendors') }}</span>
        </router-link>
    </li>
    <!-- Contact/Lead statuses -->
    <li class="nav-item pl-2" :class="{ 'active': $route.name == 'contactLeadLabels' }">
        <router-link :to="{ name: 'contactLeadLabels' }">
            <i class="ri-group-line"></i>
            <span class="nav-draft-line">{{ $t('label.contact lead statuses') }}</span>
        </router-link>
    </li>
    </div>
    </li>

    <!-- Asset Management -->
    <li class="nav-item">
        <a class="nav-link d-flex align-items-center collapsed" href="#navbar-asset-management" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="navbar-asset-management">
            <i class="ri-tools-line"></i>
            <span class="nav-link-text">Assets</span>
        </a>
        <div class="collapse" id="navbar-asset-management" style="" :class="{ 'show': isActive('assets') || isActive('assetItemTypes') || isActive('purchase-items') }">
            <!-- All assets -->
    <li class="nav-item pl-2" :class="{ 'active': isActive('assets') }">
        <router-link :to="{ name: 'assets' }">
            <i class="ri-truck-line"></i>
            <span class="nav-link-text">All Assets</span>
        </router-link>
    </li>
    <!-- Asset Item Types -->
    <li class="nav-item pl-2" :class="{ 'active': isActive('assetItemTypes') }">
        <router-link :to="{ name: 'assetItemTypes' }">
            <i class="ri-hammer-line"></i>
            <span class="nav-link-text">{{ $t('label.asset item types') }}</span>
        </router-link>
    </li>
    <!-- Purchase Items -->
    <li class="nav-item pl-2" :class="{ 'active': isActive('purchase-items') }">
        <router-link :to="{ name: 'purchase-items-list' }">
            <i class="ri-tools-line"></i>
            <span class="nav-link-text">Purchase Items</span>
        </router-link>
    </li>
    </div>
    </li>

    <li class="row my-2 sp-line-separator"></li>
    <!-- Settings -->
    <li class="nav-item">
        <a class="nav-link d-flex align-items-center collapsed" href="#navbar-settings" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="navbar-settings">
            <i class="ri-settings-5-line"></i>
            <span class="nav-link-text">{{ $t('label.settings') }}</span>
        </a>
        <div class="collapse" id="navbar-settings" style="" :class="{ 'show': isActive(['companySettings', 'accountingSettings', 'timesheetSettings', 'taskSettings']) }">
            <!-- Company Settings -->
    <li class="pl-3" :class="{ 'active': $route.name == 'companySettings' }">
        <router-link :to="{ name: 'companySettings' }">
            <i class="ri-building-2-line"></i>
            <span class="nav-link-text">{{ $t('label.company') }}</span>
        </router-link>
    </li>
    <!-- Accounting Settings -->
    <li class="pl-3" :class="{ 'active': $route.name == 'accountingSettings' }">
        <router-link :to="{ name: 'accountingSettings' }">
            <i class="ri-money-dollar-circle-line"></i>
            <span class="nav-link-text">{{ $t('label.accounting') }}</span>
        </router-link>
    </li>
    <!-- Timesheet Settings -->
    <li class="pl-3" :class="{ 'active': $route.name == 'timesheetSettings' }">
        <router-link :to="{ name: 'timesheetSettings' }">
            <i class="ri-time-line"></i>
            <span class="nav-link-text">{{ $t('label.timesheet') }}</span>
        </router-link>
    </li>
    <!-- Task Settings -->
    <li class="pl-3" :class="{ 'active': $route.name == 'taskSettings' }">
        <router-link :to="{ name: 'taskSettings' }">
            <i class="ri-todo-line"></i>
            <span class="nav-link-text">{{ $t('label.tasks') }}</span>
        </router-link>
    </li>
    </div>
    </li>

    <!-- Content Management -->
    <li class="nav-item">
        <a class="nav-link d-flex align-items-center collapsed" href="#navbar-content-management" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="navbar-content-management">
            <i class="ri-stack-line"></i>
            <span class="nav-link-text">{{ $t('label.content management') }}</span>
        </a>
        <div class="collapse" id="navbar-content-management" style="" :class="{ 'show': isActive(['customfields', 'jobtypes', 'jobTemplates', 'tasktypes', 'users', 'crews']) }">

            <!-- Custom Fields -->
    <li class="pl-2" :class="{ 'active': $route.name == 'customfields' }">
        <router-link :to="{ name: 'customfields' }">
            <i class="ri-draft-line"></i>
            <span class="nav-link-text">{{ $t('label.custom fields') }}</span>
        </router-link>
    </li>
    <!-- Job Types -->
    <li class="pl-2" :class="{ 'active': $route.name == 'jobtypes' }">
        <router-link :to="{ name: 'jobtypes' }">
            <i class="ri-draft-line"></i>
            <span class="nav-link-text">{{ $t('label.job types') }}</span>
        </router-link>
    </li>
    <!-- Job Templates -->
    <li class="pl-2" :class="{ 'active': $route.name == 'jobTemplates' }">
        <router-link :to="{ name: 'jobTemplates' }">
            <i class="ri-draft-line"></i>
            <span class="nav-link-text">{{ $t('label.job templates') }}</span>
        </router-link>
    </li>
    <!-- Task Types -->
    <li class="pl-2" :class="{ 'active': $route.name == 'tasktypes' }">
        <router-link :to="{ name: 'tasktypes' }">
            <i class="ri-draft-line"></i>
            <span class="nav-link-text">{{ $t('label.task types') }}</span>
        </router-link>
    </li>

    <!-- Users -->
    <li class="pl-2" :class="{ 'active': $route.name == 'users' }">
        <router-link :to="{ name: 'users' }">
            <i class="ri-team-line"></i>
            <span class="nav-link-text">{{ $t('label.users') }}</span>
        </router-link>
    </li>
    <!-- Crews -->
    <li class="pl-2" :class="{ 'active': $route.name == 'crews' }">
        <router-link :to="{ name: 'crews' }">
            <i class="ri-group-2-line"></i>
            <span class="nav-link-text">{{ $t('label.crews') }}</span>
        </router-link>
    </li>
    </div>
    </li>


    <!-- Tools -->
    <!-- <li class="nav-item">
        <a class="nav-link d-flex align-items-center collapsed" href="#navbar-tootle" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="navbar-tootle">
            <i class="ri-tools-line"></i>
            <span class="nav-link-text">{{ $t('label.tools') }}</span>
        </a>
        <div class="collapse" id="navbar-tootle" style="" :class="{ 'show': isActive(['messages', 'dataImport']) }">

           
    <li class="nav-item pl-3" :class="{ 'active': $route.name == 'messages' }">

        <router-link :to="{ name: 'messages' }">
            <i class="ri-message-2-line"></i>
            <span class="nav-link-text">{{ $t('label.messages') }}</span>
        </router-link>

    </li>
   
   <li class="nav-item pl-3" :class="{ 'active': $route.name == 'dataImport' }">

        <router-link :to="{ name: 'dataImport' }">
            <i class="ri-contacts-book-upload-line"></i>
            <span class="nav-link-text">{{ $t('label.data import') }}</span>
        </router-link>
    </li> 
    </div>
    </li> -->

    <!-- Purchase Plan -->
    <li class="nav-item" :class="{ 'active': $route.name == 'purchase-plan' }">
        <router-link :to="{ name: 'purchase-plan' }">
            <i class="ri-article-line"></i>
            <span class="nav-link-text">Purchase Plan</span>
        </router-link>
    </li>
    </ul>

    <!-- Field User -->
    <ul class="navbar-nav" v-if="user.role_id > 2">
        <span>
            <!-- Tasks -->
            <li class="nav-item" :class="{ 'active': $route.name == 'fieldTasks' }">
                <router-link :to="{ name: 'fieldTasks' }">
                    <i class="ri-todo-line"></i>
                    <span class="nav-link-text">{{ $t('label.tasks') }}</span>
                </router-link>
            </li>
            <!-- Work History -->
            <li class="nav-item" :class="{ 'active': $route.name == 'fieldWorkHistory' }">
                <router-link :to="{ name: 'fieldWorkHistory' }">
                    <i class="ri-history-line"></i>
                    <span class="nav-link-text">{{ $t('label.work history') }}</span>
                </router-link>
            </li>
            <!-- Time off Requests -->
            <li class="nav-item" :class="{ 'active': $route.name == 'fieldTimeoffRequests' }">
                <router-link :to="{ name: 'fieldTimeoffRequests' }">
                    <i class="ri-calendar-event-line"></i>
                    <span class="nav-link-text">{{ $t('label.time off requests') }}</span>
                </router-link>
            </li>
        </span>
    </ul>

    </div>
    </perfect-scrollbar>

    <!-- Clock in message -->
    <!-- <div v-if="(user.role_id == 2)">
        <div class="pl-3 pr-3 timer-loading">
            <div class="card mb-0 h-100">
                <div class="card-header pt-3 pl-2 pr-2 border-0 bg-lightgray d-flex justify-content-center align-items-center h-100">

                </div>
            </div>
        </div>
        <user-clockin :user="user" class="d-none d-lg-block" @clockIn="viewClockInModal = true;" @clockOut="viewClockOutModal = true;"></user-clockin>
    </div> -->
    </nav>


    <!-- Needs to be in parent z-index stacking context -->
    <clock-in-modal v-if="viewClockInModal" @closeFnc="viewClockInModal = false;"></clock-in-modal>
    <clock-out-modal v-if="viewClockOutModal" @closeFnc="viewClockOutModal = false;"></clock-out-modal>
    </div>
</template>

<script>
import ClockInModal from '@/components/TimeManagement/ClockInModal.vue';
import ClockOutModal from '@/components/TimeManagement/ClockOutModal.vue';
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';

export default {
    props: [],
    components: {
        ClockInModal,
        ClockOutModal
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            company: JSON.parse(localStorage.getItem('company_settings')),
            language: null,
            showNav: false,
            adminTools: false,
            adminSettings: false,
            adminContentMan: false,
            adminMenuOpen: false,
            selectedMenuItem: 0,
            navTogglable: false,
            viewClockInModal: false,
            viewClockOutModal: false,
            clockingInActive: false,
            currentlyClockedIn: false,
        }
    },
    computed: {
        ...mapGetters({
            // company: 'company', 
            // coordinates: 'coordinates', 
            userClockin: 'time/userClockin',
            hasLoaded: 'time/hasLoaded',
            isEdit: 'time/isEdit',
        }),
    },
    watch: {
        hasLoaded(newVal) {
            this.clockingInActive = newVal;
        },
    },
    created() {
        // Watch the window resize to programatically toggle of side nav
        window.addEventListener("resize", this.toggleNavBarOnResize);
        bus.$on('checkClockinStatus', () => {
            this.$store.dispatch('time/getUserCurrentClockin')
        })
    },
    mounted() {
        this.isAdminMenuOpen();

        // set the selected language based on users saved settings
        this.language = (this.user.language) ? this.user.language : 'en';

        // Set nav bar status
        this.toggleNavBarOnResize();
        bus.$on('toggleSideNav', this.showOrHideMenu);
        if (window.innerWidth > 1199) {
            this.showOrHideMenu()
        }

        // Initialize vuex localStorage watcher
        this.$store.dispatch('time/initLocalStorageWatcher');

        setTimeout(() => {
            this.$store.dispatch('time/getUserCurrentClockin');

            if (this.user)
                this.$store.dispatch('getLoggedInUser');
        }, 1000);
    },
    destroyed() {
        // Destroy event listeners
        window.removeEventListener("resize", this.toggleNavBarOnResize);
        bus.$off('toggleSideNav', this.showOrHideMenu);
    },
    methods: {
        isActive(route) {
            if (Array.isArray(route)) {
                route.forEach(element => {
                    return this.$route.path.includes(element);
                });
            } else {
                return this.$route.path.includes(route);
            }
        },
        toggleNavBar() {
            if (window.innerWidth < 1200) {
                bus.$emit('toggleSideNav');
            }
        },
        showOrHideMenu() {
            let contentElm = document.getElementById("spMainContent")
            let navElm = document.getElementById("spLeftNav")
            let footerElm = document.getElementById("spFooterContent")

            if (!this.showNav) {
                this.showNav = true
                if (navElm) navElm.style.width = "250px";

                if (window.innerWidth > 1199) {
                    if (contentElm) contentElm.style.marginLeft = "250px";
                    if (footerElm) footerElm.style.marginLeft = "250px";
                }
            } else {
                this.showNav = false
                if (navElm) navElm.style.width = "0";
                if (contentElm) contentElm.style.marginLeft = "0";
                if (footerElm) footerElm.style.marginLeft = "0";
            }
        },
        toggleNavBarOnResize(event) {
            // Allow for togglable side nav when window is smaller
            if (window.innerWidth < 1200) {
                if (!this.navTogglable) {
                    bus.$emit('toggleSideNav');
                    this.navTogglable = true;
                }
            } else {
                this.navTogglable = false;
            }
        },

        setLang(lang) {
            // Update the users perfered language
            this.$store.dispatch('Auth/setLoading', true);
            this.language = lang;
            axios.post('/update-language', {
                language: lang
            })
                .then(response => {
                    this.$store.dispatch('Auth/getUserLocalStrings', {
                        language: lang
                    });
                })
                .catch(err => {
                    window.location.reload();
                })
                .finally(() => {
                    setTimeout(() => {
                        this.$store.dispatch('Auth/setLoading', false)
                    }, 700);

                })
        },
        isAdminMenuOpen() {
            // Open the appropriate left nave menu based on the page the user is on
            let urlSegments = this.$route.path.split("/");

            if (urlSegments[urlSegments.length - 3] == "admin") {
                this.adminMenuOpen = true;

                if (urlSegments[urlSegments.length - 2] == "settings")
                    this.adminSettings = true;
                if (urlSegments[urlSegments.length - 2] == "contentManagement")
                    this.adminContentMan = true;
                if (urlSegments[urlSegments.length - 2] == "tools")
                    this.adminTools = true;
            } else {
                this.adminMenuOpen = false;
            }
        },
        changeSelectedMenu(number) {
            if (number == this.selectedMenuItem) {
                this.selectedMenuItem = 0
            } else {
                this.selectedMenuItem = number
            }
        },
    }
}
</script>

<style scoped>
.sp-sidenav {
    background-color: #0b2f47;
    border-right: 1px solid #124d75;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 5px;
    position: fixed;
    top: 0;
    transition: .5s;
    width: 250px;
    z-index: 5 !important;
}

.sp-sidenav a {
    color: #e0e0e0;
    display: block;
    font-size: 15px;
    padding: 6px 8px;
    text-decoration: none;
    white-space: nowrap;
}

.sp-sidenav i {
    font-size: 20px;
    padding-right: 10px;
}

.sp-sidenav a:hover {
    color: #fff !important;
    /* font-weight: 700; */
}

.sp-sidenav a:not([href]):not([tabindex]) {
    color: #e0e0e0;
    text-decoration: none;
}

.sp-submenu-icon {
    font-size: 25px !important;
}

.sp-sidenav .nav-item .nav-link.active {
    border-radius: 0.375rem;
    background: #f6f9fc;
}

.navbar-nav .nav-link[data-toggle=collapse]:after {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Bootstrap-icons';
    font-size: 12px;
    content: "\F285";
    margin-left: auto;
    color: #e0e0e0;
    transition: all .2s ease-in-out;
    margin-right: 10px;
}

@media (prefers-reduced-motion:reduce) {
    /* .navbar-nav .nav-link[data-toggle=collapse]:after {
        transition: none
    } */
}

.navbar-nav .nav-link[data-toggle=collapse][aria-expanded=true]:after {
    color: #212529;
    transform: rotate(90deg)
}

.sp-line-separator {
    background-color: #e7ebf1;
    padding-top: 1px;
    border-radius: 50px;
}

li.nav-item {
    margin: 5px 0;
}

.navbar-nav li.active a,
.navbar-nav li.active i,
.navbar-nav li.active span {
    /* color: #5e72e4 !important; */
    color: #005486 !important;
    font-weight: 600;
}

.navbar-nav li a {
    /* color: #5e72e4 !important; */
    display: flex;
    align-items: center;
}
</style>