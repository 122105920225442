<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="accountingSettingsContainer">
            <!-- Page Header -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.accounting settings')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.administrative') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.settings') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.accounting') }}
                    </li>
                </breadcrumbs>
            </div>

            <!-- Page Body -->
            <div class="">
                <div class="card">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col-8">
                                <h3 class="mb-0">{{ $t('label.accounting settings') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- Minimum wage -->
                        <h3 class="mb-0">{{ $t('label.minimum wage') }}</h3>
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">$</span>
                                </div>
                                <input type="number" class="form-control" v-model="form.minimum_wage">
                            </div>
                        </div>
                        <hr>
                        <!-- Sync to Quickbooks 4/2023 Mick wait for integration -->
                        <div class="row">
                            <div class="col-12">
                                <h3 class="mb-0">Sync to Quickbooks Online</h3>
                                <a style="display:block;margin-bottom: 10px;font-size: 14px;" href="/Quickbooks_Online_Configuration_steps.pdf" target="_blank">How do I get my Quickbooks keys?</a>
                                <div class="form-group">
                                    <div class="row m-0 pt-1">
                                        <div class="custom-control custom-radio mr-3">
                                            <input v-model="form.quickbooks_sync" name="quickbooks_sync" id="sync_to_qb_yes" class="custom-control-input" value="1" type="radio">
                                            <label class="custom-control-label" for="sync_to_qb_yes">{{ $t('label.yes') }}</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                            <input v-model="form.quickbooks_sync" name="quickbooks_sync" id="sync_to_qb_no" class="custom-control-input" value="0" type="radio">
                                            <label class="custom-control-label" for="sync_to_qb_no">{{ $t('label.no') }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="form.quickbooks_sync == 1">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="">Quickbooks Client ID</label>
                                        <input type="text" class="form-control" v-model="form.quickbooks_client_id">
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="">Quickbooks Client Secret</label>
                                        <input type="text" class="form-control" v-model="form.quickbooks_client_secret">
                                    </div>
                                    <p v-if="isConnectionAlive"><i class="bi bi-check-circle text-info"></i> Quickbooks Online is connected!</p>
                                    <a class="btn btn-primary" :href="authUrl" v-else-if="isClientAndSecretIDSet === true || isAuthUrl === true">Connect to Quickbooks Online</a>
                                </div>
                                <hr>
                            </div>
                        </div>

                        <!-- Use Payments -->
                        <!-- <div class="row">
                                <div class="col-12">
                                    <h3 class="mb-0">{{ $t('label.use payments') }}</h3>
                                    <div class="form-group">
                                        <div class="row m-0 py-1">
                                            <div class="custom-control custom-radio mr-3">
                                                <input name="payment" v-model="form.payment" id="paymentYes" class="custom-control-input" value="1" type="radio">
                                                <label class="custom-control-label" for="paymentYes">{{ $t('label.yes') }}</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input name="payment" v-model="form.payment" id="paymentNo" class="custom-control-input" value="0" type="radio">
                                                <label class="custom-control-label" for="paymentNo">{{ $t('label.no') }}</label>
                                            </div>
                                        </div>
                                        <div v-if="form.payment == 1" class="row mt-4 mb--4">
                                            <div class="col-12">
                                                What payment will you be using?
                                                <h3 class="mb-0">{{ $t('label.what payment will you be using') }}</h3>
                                                <div class="form-group">
                                                    Stripe or Authorize.net
                                                    <div class="row m-0 py-1">
                                                        <div class="custom-control custom-radio mr-3">
                                                            <input v-model="form.payment_method" id="payment_method_1" name="payment_method" class="custom-control-input" value="1" type="radio">
                                                            <label class="custom-control-label" for="payment_method_1">{{ $t('label.stripe') }}</label>
                                                        </div>
                                                        <div class="custom-control custom-radio">
                                                            <input v-model="form.payment_method" id="payment_method_2" name="payment_method" class="custom-control-input" value="2" type="radio">
                                                            <label class="custom-control-label" for="payment_method_2">{{ $t('label.authorize dot net') }}</label>
                                                        </div>
                                                    </div>
                                                    Stripe Keys
                                                    <div v-if="form.payment_method == 1" class="row mt-3 mx-0 py-1">
                                                        <div class="col-6 pl-0">
                                                            <div class="form-group mb-0">
                                                                <label class="form-control-label text-muted">{{ $t('label.publishable key') }}</label>
                                                                <input type="text" v-model="form.publishable_key" name="publishable_key" class="form-control" placeholder="Publishable Key" required autofocus>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <div class="form-group mb-0">
                                                                <label class="form-control-label text-muted">{{ $t('label.secret key') }}</label>
                                                                <input type="text" v-model="form.secret_key" name="secret_key" class="form-control" placeholder="Secret Key" required autofocus>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    Authorize.net Keys
                                                    <div v-if="form.payment_method == 2" class="row mt-3 mx-0 py-1">
                                                        <div class="col-4 pl-0">
                                                            <div class="form-group mb-0">
                                                                <label class="form-control-label text-muted">{{ $t('label.api login id') }}</label>
                                                                <input type="text" v-model="form.api_login_id" name="api_login_id" class="form-control" placeholder="API Login ID" required autofocus>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 pl-0">
                                                            <div class="form-group mb-0">
                                                                <label class="form-control-label text-muted">{{ $t('label.transaction key') }}</label>
                                                                <input type="text" v-model="form.transaction_key" name="transaction_key" class="form-control" placeholder="Transaction Key" required autofocus>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 pl-0">
                                                            <div class="form-group mb-0">
                                                                <label class="form-control-label text-muted">{{ $t('label.public client key') }}</label>
                                                                <input type="text" v-model="form.public_client_key" name="public_client_key" class="form-control" placeholder="Public Client Key" required autofocus>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <hr class="mb-5">
                                    </div>
                                </div>
                            </div> -->

                        <!-- Use Invoicing -->
                        <div class="row mt--3">
                            <div class="col-12">
                                <h3 class="mb-0">{{ $t('label.use invoicing') }}</h3>
                                <div class="form-group">
                                    <div class="row m-0 py-1">
                                        <div class="custom-control custom-radio mr-3">
                                            <input name="invoice" id="invoiceYes" class="custom-control-input" value="1" type="radio" v-model="form.invoice">
                                            <label class="custom-control-label" for="invoiceYes">{{ $t('label.yes') }}</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                            <input name="invoice" id="invoiceNo" class="custom-control-input" value="0" type="radio" v-model="form.invoice">
                                            <label class="custom-control-label" for="invoiceNo">{{ $t('label.no') }}</label>
                                        </div>
                                    </div>
                                    <div v-if="form.invoice == 1" class="row mt-3">
                                        <!-- Starting Invoice ID -->
                                        <div class="col-2 form-group">
                                            <div class="form-group mb-0">
                                                <label class="form-control-label text-muted">{{ $t('label.starting invoice id') }}</label>
                                                <input type="text" name="invoice_start_number" class="form-control" placeholder="Starting Invoice ID" v-model="form.invoice_start_number">
                                            </div>
                                        </div>
                                        <div class="col-12 form-group">
                                            <!-- Tax Rate -->
                                            <!-- <h3 class="mb-0">{{ $t('label.tax rate') }}</h3> -->
                                            <label class="form-control-label text-muted">{{ $t('label.tax rate') }}</label>
                                            <div class="form-group">
                                                <div class="m-0 pt-1">
                                                    <div class="p-0 d-block">
                                                        <div class="input-group" style="width: 135px">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text input-group-invoice"><i class="ri-percent-line"></i></span>
                                                            </div>
                                                            <input type="number" v-model="form.tax_rate" maxlength="3" step="any" name="tax_rate" class="form-control" min="0" oninput="validity.valid||(value='')" required>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row m-0 py-1">
                                                    <small class="d-flex align-items-center">
                                                        <i class="ri-information-line"></i>{{ $t('label.percent of invoice subtotal added to the total invoice amount') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Save Footer -->
                <div class="text-center">
                    <button type="button" @click="saveSettings()" class="btn btn-primary">{{ $t('label.save settings') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';

export default {
    props: [],
    components: {},
    data() {
        return {
            loader: null,
            form: {
                // Company Settings
                payment: null,
                invoice: null,
                tax_rate: null,
                secret_key: null,
                api_login_id: null,
                payment_method: null,
                transaction_key: null,
                publishable_key: null,
                invoice_start_number: null,
                public_client_key: null,
                minimum_wage: null,
                quickbooks_sync: null,
                quickbooks_client_id: null,
                quickbooks_client_secret: null,
            },
            authUrl: null,
            isAuthUrl: null,
            isConnectionAlive: null,
            isClientAndSecretIDSet: null
        }
    },
    computed: {
        ...mapGetters(['company'])
    },
    created() {
        this.loader = this.$loading.show();
        this.$store.dispatch('getCompanySettings').then(() => {
            this.initForm();
            this.authUrl = this.company.settings.authUrl;
            this.isAuthUrl = this.company.settings.isAuthUrl;
            this.isConnectionAlive = this.company.settings.isConnectionAlive;
            this.isClientAndSecretIDSet = this.company.isClientAndSecretIDSet;
            this.loader.hide();
        });
    },
    watch: {
        '$route': {
            immediate: true,
            handler(to, from) {
                if (to.query.code && to.query.realmId) {
                    let code = to.query.code;
                    let realmId = to.query.realmId;
                    this.loader = this.$loading.show();
                    axios.post('/quickbooks-update-token', { code: code, realmId: realmId }).then(response => {
                        // this.$router.replace('/admin/settings/accounting');

                        let path = window.location.href.split('?')[0];
                        window.location.href = path;
                        this.loader.hide();
                    }).catch(err => console.log(err));
                }
            }
        }
    },
    mounted() {

    },
    methods: {
        initForm() {
            // Set form values
            for (const key in this.company.settings)
                if (key in this.form)
                    this.form[key] = this.company.settings[key];
        },

        saveSettings() {
            let accountingSettings = JSON.parse(JSON.stringify(this.form));
            accountingSettings.company_id = this.company.id;
            this.loader = this.$loading.show();
            axios.post('/settings', accountingSettings).then(response => {
                bus.$emit('banner-success', {
                    message: this.$t('label.company settings saved successfully')
                });
                localStorage.setItem('company_settings', JSON.stringify({ ...this.company, settings: this.form }))
                this.loader.hide();
                window.location.reload();
            }).catch(err => console.log(err));
        },


        // Disabled for now. Quickbooks integration still needs to happen first.
        // Should move this functionality to the server side both for validating 
        // syncing to quickbooks and actually setting the company setting value.
        // - Tyler Deschamps 1/4/2023
        // 
        // onToggleQuickbooks(event) {
        //     if( event.target.value == 1 ) {
        //         this.$store.dispatch('isSyncToQb', {
        //             'isSyncResult': null,
        //             'isSyncing': true
        //         });
        //         axios.get('/get-auth-url')
        //         .then(response => {
        //             let authUrl = response.data.data;
        //             let accessToken = response.data.accessToken;

        //             if (!accessToken) {
        //                 var that = this
        //                 let win = window.open(authUrl, 'connectPopup');
        //                 let pollOAuth = window.setInterval(function () {
        //                     try {
        //                         if( win.document ) {
        //                             if (win.document.URL.indexOf("code") != -1) {
        //                                 window.clearInterval(pollOAuth);
        //                                 win.close();

        //                                 that.syncToQuickbooks(event.target.value)
        //                             }
        //                         }
        //                     } catch (e) {
        //                         console.log(e)
        //                     }
        //                 }, 100);
        //             } else {
        //                 this.syncToQuickbooks(event.target.value)
        //             }
        //         })
        //         .catch(error => {
        //             console.log('Get quickbook auth url error:', error)
        //         });

        //     } else {
        //         this.syncToQuickbooks(event.target.value)
        //     }
        // },
        // syncToQuickbooks(value) {
        //     let params = {
        //         sync_to_qb: value
        //     }

        //     axios.post('/sync-to-quickbooks-settings', params)
        //     .then(response => {

        //         let messageText = value == 1 ? 'Sync to Quickbooks is successful!' : 'Sync to Quickbooks is removed!';
        //         bus.$emit('banner-success', { 
        //             message: messageText,
        //         });
        //     })
        //     .catch(err => {
        //         //console.log('list carriers error:', error)
        //     });
        // },
    }
}
</script>
